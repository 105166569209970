.PanelEven {
  text-align: left;
  background-color: #2ab9c4;
  color: white;
  margin-bottom: 30px;
}

.Panel-logo {
  height: 40vmin;
  pointer-events: none;
}

.Panel-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Panel-link {
  color: #61dafb;
}

